import CipherSchools from "./Cipherschools_darkmode@2x.png";
import NavLogoLight2 from "./Cipherschools_lightmode@3x.png";
import NavLogoLight from "./Cipherschools_lightmode@2x.png";
import NavLogoDark from "./Cipherschools_darkmode@2x.png";
import NavLogo from "./Cipherschools_icon@2x.png";
import Plutonn from "./Plutonn_logo_192.png";
import Template1 from "./template1.png";
import Template2 from "./template2.png";
import Template3 from "./template3.png";

// Alumni Page Photos
import BannerLeft from "./BannerLeft.png";
import BannerUp from "./BannerTop.png";
import Ellipse1 from "./Ellipse.png";
import Ellipse2 from "./Ellipse2.png";
import Ellipse3 from "./Ellipse3.png";
import QuoteMark from "./Quote mark.png";
import Company1 from "./Company1.svg";
import Company2 from "./Company2.svg";
import Company3 from "./Company3.svg";
import Company4 from "./Company4.svg";
import Company5 from "./Company5.svg";
import Company6 from "./Company6.svg";
import Company7 from "./Company7.svg";
import Avatar from "./avatar.jpeg";
import CertificateLogo from "./tc.png";
import Promo from "./Promo.webp";
import FooterSvg from "./footer.svg";
import HeaderSvg from "./Header.svg";
import Profile1 from "./profile1.webp";
import Profile2 from "./profile2.webp";
import Profile3 from "./profile3.webp";
import Profile4 from "./profile4.webp";
import Profile5 from "./profile5.webp";
import Profile6 from "./profile6.webp";
import R2 from "./r2.webp";
import R1 from "./r1.webp";
import R3 from "./r3.webp";
import R4 from "./r4.webp";

// Certificate Automation image

import feedback from "./feedback.png";
import notFound from "./404.gif";

import CipherScholsLaptop from "./CipherSchools-laptop.webp";
import TestEligibility from "./test-eligibility.png";
import PlutonnLaptop from "./Plutonn-laptop.webp";
import TestBackground from "./test-background.svg";
import LastAttempt from "./last-attempt.png";
import NoAttempts from "./no-attempts.jpg";
import about from "./About_Banner.png";
import Test from "./test-img.png";
import Team1 from "./team1.webp";
import Team2 from "./team2.webp";

import InvitedUser from "./invited-user.png";

import warningImg from "./warning-img.png";

const Images = {
  CipherSchools,
  NavLogoLight2,
  NavLogoLight,
  NavLogoDark,
  NavLogo,
  Plutonn,
  Template1,
  Template2,
  Template3,
  BannerLeft,
  BannerUp,
  Avatar,
  QuoteMark,
  Ellipse1,
  Ellipse2,
  Ellipse3,
  Company1,
  Company2,
  Company3,
  Company4,
  Company5,
  Company6,
  Company7,
  CertificateLogo,
  notFound,
  feedback,
  CipherScholsLaptop,
  PlutonnLaptop,
  about,
  Promo,
  FooterSvg,
  HeaderSvg,
  Team1,
  Team2,
  Profile1,
  Profile2,
  Profile3,
  Profile4,
  Profile5,
  Profile6,
  R2,
  R1,
  R4,
  R3,
  Test,
  NoAttempts,
  LastAttempt,
  TestBackground,
  TestEligibility,
  InvitedUser,
  warningImg,
};
export default Images;
